import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  createTheme,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

import Body from './pages/Body'
import { SideDrawer } from './components/organisms/v2/sideDrawer'
import { getDesignTokens } from './utils/mui'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from './state'
import Helika, { DisableDataSettings, EventsBaseURL } from 'helika-sdk'
import _ from 'lodash'
import { useGetDrawer } from 'src/utils/drawer'
import { closeDrawerWidth, drawerWidth } from './consts/sizes'
import { newColor } from './consts/colors'
import { HEADER } from './consts/sizes'
import { LayoutHeader } from './components/organisms/v2/layout'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const ColorModeContext = React.createContext({
  toggleColorMode: (mode: PaletteMode) => { },
})
export const useColorMode = () => useContext(ColorModeContext)

function App() {
  const dispatch = useDispatch()
  const { setSDKData } = bindActionCreators(actionCreators, dispatch)
  const sdk = useSelector((state: any) => state.sdk)
  const isDrawerOpen = useGetDrawer()
  let noNavBar = ['/', '/game_demo', '/game_demo_internal'].includes(
    window.location.pathname,
  )
  const { isAuthenticated } = useAuth0()

  let defaultTheme: any = 'dark'
  try {
    defaultTheme = (localStorage.getItem('theme') as 'light' | 'dark') ?? 'dark'
    localStorage.setItem('theme', defaultTheme)
  } catch (e) {
    console.log('Cookies disabled')
  }
  const [mode, setMode] = useState<PaletteMode>(defaultTheme)
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: (mode: PaletteMode) => {
        setMode(mode)
      },
    }),
    [],
  )

  useEffect(() => {
    async function initiateSDK() {
      if (!_.isEmpty(sdk)) return
      try {
        let helikaSdk = new Helika.EVENTS(
          process.env.REACT_APP_EVENTS_API_KEY!,
          'helika_portal',
          process.env.REACT_APP_ENVIRONMENT === 'PROD'
            ? EventsBaseURL.EVENTS_PROD
            : EventsBaseURL.EVENTS_DEV,
        )
        setSDKData({
          type: 'SET_SDK',
          payload: helikaSdk,
        })
        helikaSdk.setDataSettings(DisableDataSettings.None)
        await helikaSdk.startSession()
      } catch (e) {
        console.error('Could not load Helika SDK')
      }
    }
    initiateSDK()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update the theme only if the mode changes
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode])

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
            }}
          >
            {isAuthenticated && <LayoutHeader />}
            <div
              style={{
                display: 'flex',
                height: '100%',
              }}
            >
              <SideDrawer />
              <div
                className="widthTransition"
                style={{
                  backgroundColor:
                    theme.palette.mode === 'dark' ? 'black' : 'white',
                  marginTop: isAuthenticated ? `${HEADER.height}` : `0`,
                  display: 'flex',
                  flexDirection: 'column',
                  height: isAuthenticated
                    ? `calc(100% - ${HEADER.height})`
                    : '100%',
                  width: noNavBar
                    ? '100%'
                    : isDrawerOpen
                      ? `calc(100% - ${drawerWidth})`
                      : `calc(100% - ${closeDrawerWidth})`,
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }}
              >
                <div
                  id="body-comp"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: theme.palette.mode === 'dark' ? 'white' : 'black',
                    background:
                      theme.palette.mode === 'dark' ? 'black' : newColor.cultured,
                    minHeight: `calc(100vh - ${HEADER.height})`,
                    overflow: 'auto',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Body />
                </div>
              </div>
            </div>
          </div>
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

export default App
